import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Box, Divider, Text, useDisclosure } from '@chakra-ui/core'
import * as linkify from 'linkifyjs'

import BaseModal from 'components/CommonComponents/BaseModal'
import { OptionType } from '../constants'
import { Switcher } from 'components/CommonComponents/Switcher'
import LocaleText from 'components/LocaleText'
import { RootState } from 'constants/interfaces'
import { determinePayloadGroups, determinePostContent, getPostType, isUserIncludedInGroups, options } from './utils'
import useAvatar from 'hooks/useAvatar'
import { AudienceSelectionBlock } from './AudienceSelectionBlock'
import { InputBlock } from './InputBlock'
import useLocaleText from 'components/useLocaleText'
import { MediaContentBlock } from './MediaContentBlock'
import { getLocalStorageItem, isValidDomain, updateLocalStorageItem } from 'utils'
import AudiencePublicationsModal from 'components/CommonComponents/AudiencePublicationModal'
import GroupsTree from 'components/CommonComponents/GroupsTree'
import { UsersList } from 'components/CommonComponents/UsersListModal'
import strings from 'constants/strings'
import { IHandleUpdatePost, createGroup, createPop, handleCreatePost, handleUpdatePost } from 'redux/actions/feed'
import { getTreeNodesIds } from 'components/CommonComponents/GroupsTree/utils'
import { PopInitiator, PopType } from 'components/CommonComponents/PopModal/utils'
import { setDummyPop } from 'redux/actions/pops'
import { AppDispatch } from 'config/redux'
import keys from 'constants/keys'
import { useSearchPage } from 'hooks/useSearchPage'
import { useMediaState } from '../../../hooks/useMediaState'
import { useAudienceState } from '../../../hooks/useAudienceState'
import { usePostTextContent } from './hooks/usePostTextContent'
import { LinkAttachmentModal } from './LinkAttachmentModal'
import PostModalFooter from './PostModalFooter'
import dayjs from 'dayjs'
import { TaskCreationBody, createTask } from 'redux/actions/tasks_v2'
import { FormField, SubTask } from '../PollTaskForm/Subtask/utils'
import { TagsList } from 'components/CommonComponents/TagsListModal'

interface PostModal {
  isOpen: boolean
  onClose: () => void
  files?: string[]
  videos?: string[]
  audios?: string[]
  groups?: string[]
  postTitle?: string
  users?: string[]
  tags?: string[]
  images?: string[]
  postType?: number
  post_id?: string
  created_at_ts?: number
  ref_id?: string | null
  isChat?: boolean
  isEditing?: boolean
  admins?: string[]
}
export const PostModal = ({
  isOpen,
  onClose,
  files,
  videos,
  audios,
  groups,
  tags: tagIds,
  postTitle,
  users,
  images,
  postType,
  post_id,
  created_at_ts,
  ref_id,
  admins,
  isChat = false,
  isEditing = false,
}: PostModal) => {
  const dispatch: AppDispatch = useDispatch()
  const { isSearchPage } = useSearchPage()
  const {
    isOpen: isLinkAttachmentModalOpen,
    onOpen: onOpenLinkAttachmentModal,
    onClose: onCLoseLinkAttachmentModalOpen,
  } = useDisclosure()
  const uid = useSelector((state: RootState) => state.auth.uid!)
  const isLoading = useSelector((state: RootState) => state.pops.isLoading)
  const { fetching } = useSelector((state: RootState) => state.feed)
  const {
    config: {
      default_group,
      user_tags,
      retailUsersObject,
      first_name,
      last_name,
      user_role,
      tags,
      groups: configGroups,
    },
    activeGroupID,
  } = useSelector((state: RootState) => state.config)

  const avatar = useAvatar(uid)

  const [isSwitchMultipleAnswersActive, setIsSwitchMultipleAnswersActive] = useState(false)
  const [isSwitchIndividualResultsActive, setIsSwitchIndividualResultsActive] = useState(false)
  const [fromTs, setFromTs] = useState('')
  const [deadLine, setDeadLine] = useState('')
  const [questionText, setQuestionText] = useState('')
  const [pollOptions, setPollOptions] = useState<string[]>(['', ''])
  const [pollDescription, setPollDescription] = useState('')
  const [pollSubTasks, setPollSubTasks] = useState<FormField[]>([])
  const [isValidPollSubtasks, setIsValidPollSubtasks] = useState(false)

  const [isRecurring, setIsRecurring] = useState(false)
  const [recurrenceInterval, setRecurrenceInterval] = useState<number | null>(1)
  const [recurrenceFrequency, setRecurrenceFrequency] = useState<number | null>(0)
  const [taskView, setTaskView] = useState<number>(keys.TASK_V2_VIEWS.POLL)

  const [selectedOptionType, setSelectedOptionType] = useState<string>(getPostType(postType))
  const [isAudienceOptionsModalOpen, setIsAudienceOptionsModalOpen] = useState(false)
  const [isSwitchPopActive, setIsSwitchPopActive] = useState(true)
  const [isSwitchAnnouncementActive, setIsSwitchAnnouncementActive] = useState(false)
  const [attachedLink, setAttachedLink] = useState(ref_id ?? null)
  const { editorValue, setEditorValue, title, setTitle, message, setMessage, clearTextContentState } =
    usePostTextContent({ postTitle })

  const {
    media,
    uploadedFilesURL,
    uploadedImagesURL,
    uploadedVideosURL,
    audioRecords,
    handleFileUpload,
    handleMediaUploading,
    deleteFileHandler,
    removeAudioRecord,
    sendAudioRecord,
    isUploadLoading,
    handlerDeleteAllMedia,
    handleSingleMediaUploading,
    handleDeleteMedia,
    addMediaHandler,
    fileInputRef,
    imageInputRef,
    formFileRef,
    clearMediaState,
  } = useMediaState({ audios, files, images, videos })

  const {
    selectedUsersUids,
    setSelectedUsersUids,
    selectedGroupsIds,
    setSelectedGroupsIds,
    selectedTagsIds,
    setSelectedTagsIds,
    groupsToPayload,
    setGroupsToPayload,
    namesForTooltip,
    splittedGroupNames,
    filteredRerailUsersByTreeUsers,
    treeUsers,
    treeGroups,
    clearAudienceState,
  } = useAudienceState({ users, groups, postType, tags, tagIds, selectedOptionType })

  const t_create_post_placeholder = useLocaleText('create_post_placeholder')

  const validLinks = useMemo(() => {
    const foundLinks = linkify.find(editorValue)
    return foundLinks.filter((link) => isValidDomain(link.href))
  }, [editorValue])

  const clearState = useCallback(() => {
    clearMediaState()
    clearAudienceState()
    clearTextContentState()
    setAttachedLink(null)
    setIsSwitchPopActive(true)
    setIsSwitchAnnouncementActive(false)
    setIsSwitchMultipleAnswersActive(false)
    setIsSwitchIndividualResultsActive(false)
    setSelectedOptionType(OptionType.POST)
  }, [clearAudienceState, clearMediaState, clearTextContentState])

  const closeModal = useCallback(() => {
    onClose()
    clearState()
  }, [clearState, onClose])

  const onTogglePopSwitch = useCallback(() => {
    setIsSwitchPopActive(!isSwitchPopActive)
  }, [isSwitchPopActive])

  const onToggleAnnouncementSwitch = useCallback(() => {
    setIsSwitchAnnouncementActive(!isSwitchAnnouncementActive)
  }, [isSwitchAnnouncementActive])

  const onToggleIndividualResultsSwitch = useCallback(() => {
    setIsSwitchIndividualResultsActive(!isSwitchIndividualResultsActive)
  }, [isSwitchIndividualResultsActive])

  const isDisabledPublishButton = () => {
    const hasNoRecipients =
      selectedOptionType === OptionType.POP
        ? isEmpty(selectedGroupsIds) && isEmpty(selectedUsersUids)
        : isEmpty(selectedGroupsIds) && isEmpty(selectedUsersUids) && isEmpty(selectedTagsIds)

    const isProcessing = isUploadLoading || fetching || isLoading

    if (hasNoRecipients || isProcessing) {
      return true
    }

    switch (selectedOptionType) {
      case OptionType.CHAT:
        const noContentChats = !isEditing ? isEmpty(message) || isEmpty(title) : isEmpty(title)

        return noContentChats

      case OptionType.POP:
        const noContentPops = isEmpty(message) || isEmpty(title)
        return noContentPops

      case OptionType.POST:
        const noContentPosts = isEmpty(editorValue)
        const noMedia =
          isEmpty(uploadedImagesURL) && isEmpty(uploadedFilesURL) && isEmpty(audioRecords) && isEmpty(uploadedVideosURL)
        return noContentPosts && noMedia
      case OptionType.POLL:
        return (
          !questionText ||
          pollOptions.filter((option) => option !== '').length < 1 ||
          !fromTs ||
          !deadLine ||
          !isValidPollSubtasks ||
          taskView === null
        )

      default:
        return false
    }
  }

  const tagsListToRender = useMemo(() => {
    const usersTags = user_tags ?? []
    const currentGroupTags = configGroups?.[activeGroupID!]?.tags ?? []
    const allTags = Array.from(new Set([...usersTags, ...currentGroupTags]))
    return tags.filter((tag) => allTags.includes(tag.sk))
  }, [activeGroupID, configGroups, tags, user_tags])

  const renderViewOptionModal = useCallback(
    (viewOption: number, setViewOption: React.Dispatch<React.SetStateAction<number>> | any) => {
      switch (viewOption) {
        case 0:
          return (
            <GroupsTree
              isOpen={isOpen}
              selectedGroupID={selectedGroupsIds}
              mode={
                selectedOptionType === OptionType.CHAT || selectedOptionType === OptionType.POLL
                  ? strings.PARTIAL_MULTILPE_MODE
                  : strings.MULTIPLE_MODE
              }
              setViewOption={setViewOption}
              setSelectedGroupID={setSelectedGroupsIds}
              setGroupsToPayload={setGroupsToPayload}
              groupsToPayload={groupsToPayload}
              isAllowSubmitWhithoutGroups={true}
              treeGroups={treeGroups}
            />
          )
        case 1:
          return (
            <UsersList
              isOpen={isOpen}
              onClose={onClose}
              setViewOption={setViewOption}
              selectedUsersUids={selectedUsersUids}
              setSelectedUsersUids={setSelectedUsersUids}
              treeUsers={filteredRerailUsersByTreeUsers}
            />
          )
        case 2:
          return selectedOptionType !== OptionType.POP ? (
            <TagsList
              isOpen={isOpen}
              onClose={onClose}
              setViewOption={setViewOption}
              tags={tagsListToRender}
              selectedTagsIds={selectedTagsIds}
              setSelectedTagsIds={setSelectedTagsIds}
            />
          ) : (
            <></>
          )

        default:
          break
      }
    },
    [
      filteredRerailUsersByTreeUsers,
      groupsToPayload,
      isOpen,
      onClose,
      selectedGroupsIds,
      selectedOptionType,
      selectedTagsIds,
      selectedUsersUids,
      setGroupsToPayload,
      setSelectedGroupsIds,
      setSelectedTagsIds,
      setSelectedUsersUids,
      tagsListToRender,
      treeGroups,
    ]
  )

  const onCreatePost = useCallback(async () => {
    try {
      const res = await dispatch(
        handleCreatePost({
          content: editorValue,
          images: uploadedImagesURL,
          refId: attachedLink ? attachedLink : null,
          groups: groupsToPayload,
          users: selectedUsersUids,
          tags: selectedTagsIds,
          files: uploadedFilesURL,
          videos: uploadedVideosURL,
          audios: audioRecords,
        })
      )
      if (res) {
        closeModal()
        updateLocalStorageItem('defaultAudience', {
          [uid]: { groups: groupsToPayload, users: selectedUsersUids, tags: selectedTagsIds },
        })
      }
    } catch (e) {
      console.log(e)
    }
  }, [
    attachedLink,
    audioRecords,
    closeModal,
    dispatch,
    editorValue,
    groupsToPayload,
    selectedTagsIds,
    selectedUsersUids,
    uid,
    uploadedFilesURL,
    uploadedImagesURL,
    uploadedVideosURL,
  ])

  const onCreatePop = async () => {
    try {
      const res = await dispatch(
        createPop({
          title,
          message,
          users: selectedUsersUids,
          groups: groupsToPayload,
          isPopPost: isSwitchPopActive,
          popImage: uploadedImagesURL?.[0],
        })
      )
      if (res) {
        onClose()
        const dummyPop = {
          pop_title: title,
          pop_body: message,
          profile_image_url: retailUsersObject?.[uid]?.profile_img_url || null,
          pop_image_url: uploadedImagesURL?.[0] || null,
          ack_btn_text: 'newsfeed_pop_ack_btn',
          created_by: uid,
          created_at_ts: Math.floor(Date.now() / 1000),
          pop_type: PopType.DUMMY_POP,
          pop_initiator: PopInitiator.APP_USER,
        }
        setTimeout(() => {
          dispatch(setDummyPop(dummyPop))
        }, 3000)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onCreateGroup = async () => {
    try {
      const isCreatorInGroups = isUserIncludedInGroups(uid, selectedGroupsIds, treeGroups)
      const isCreatorInTags = checkIsCreatorInTags()
      const groupUsers =
        isCreatorInGroups || isCreatorInTags ? selectedUsersUids : Array.from(new Set([...selectedUsersUids, uid]))

      const res = await dispatch(
        createGroup({
          title: title,
          content: message,
          groups: selectedGroupsIds,
          users: groupUsers,
          tags: selectedTagsIds,
          images: uploadedImagesURL,
          type: isSwitchAnnouncementActive ? keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT : keys.POST_TYPE.GROUP_POST,
        })
      )
      if (res) closeModal()
    } catch (e) {
      console.log(e)
    }
  }

  const onEditPost = async () => {
    if (postType === undefined || post_id === undefined || created_at_ts === undefined) return

    const newUsersToPublish = selectedUsersUids.filter((id) => !users?.includes(id))
    const usersToDelete = (users && users.filter((user: string) => !selectedUsersUids.includes(user))) || []
    const newGroupsToPublish = (
      postType !== keys.POST_TYPE.GROUP_POST ? groupsToPayload : selectedGroupsIds ?? []
    ).filter((id) => !groups?.includes(id))
    const groupsToDelete = groups?.filter(
      (id: string) => !(postType !== keys.POST_TYPE.GROUP_POST ? groupsToPayload : selectedGroupsIds ?? []).includes(id)
    )
    const newTagsToPublish = selectedTagsIds.filter((id) => !tagIds?.includes(id))
    const tagsToDelete = (tagIds && tagIds.filter((tagId: string) => !selectedTagsIds.includes(tagId))) || []
    const isCreatorInGroups = isUserIncludedInGroups(uid, groupsToPayload, treeGroups)
    const isCreatorInTags = checkIsCreatorInTags()
    const postUsers =
      isCreatorInGroups || isCreatorInTags ? selectedUsersUids : Array.from(new Set([...selectedUsersUids, uid]))

    const postContent = determinePostContent(postType, title, editorValue)
    const payloadGroups = determinePayloadGroups(postType, selectedGroupsIds, groupsToPayload)

    const postData: IHandleUpdatePost = {
      postType,
      title: postContent,
      images: uploadedImagesURL,
      postId: post_id,
      taskRefId: attachedLink,
      groups,
      users: postUsers,
      tags: selectedTagsIds,
      newUsersToPublish,
      usersToDelete,
      newGroupsToPublish,
      groupsToDelete,
      newTagsToPublish,
      tagsToDelete,
      createdAtTs: created_at_ts,
      files: uploadedFilesURL,
      audios: audioRecords,
      videos: uploadedVideosURL,
      isSearchPage,
      groupsToPayload: payloadGroups,
      isChat,
      admins: admins,
    }

    try {
      const res = await dispatch(handleUpdatePost(postData))
      if (res) {
        onClose()
        clearState()
      }
    } catch (e) {
      console.log('Failed to create post:', e)
    }
  }
  const checkIsCreatorInTags = () => {
    if (!selectedTagsIds.length) {
      return false
    }
    const selectedTags = tags.filter((tag) => selectedTagsIds.includes(tag.sk))
    const mergedTagsGroups = Array.from(new Set(selectedTags.map((tag) => tag?.groups ?? []).flat()))

    const mergedTagsUsers = Array.from(new Set(selectedTags.map((tag) => tag?.users ?? []).flat()))
    return isUserIncludedInGroups(uid, mergedTagsGroups, treeGroups) || mergedTagsUsers.includes(uid)
  }
  const createPoll = async () => {
    try {
      const recurrenceStartTime = fromTs.slice(8)
      const originaDeadLinelDate = dayjs(deadLine, keys.DEFAULT_STRING_FORMAT_TIMESTAMP)
      const newDate = originaDeadLinelDate.add(7, 'day')
      const removalDate = isRecurring ? deadLine : newDate.format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP)
      const options = pollOptions.reduce((acc, option, index) => {
        if (option !== '') {
          return { ...acc, [index]: option }
        }
        return acc
      }, {})
      const isCreatorInGroups = isUserIncludedInGroups(uid, selectedGroupsIds, treeGroups)
      const isCreatorInTags = checkIsCreatorInTags()

      const taskUsers =
        isCreatorInGroups || isCreatorInTags ? selectedUsersUids : Array.from(new Set([...selectedUsersUids, uid]))

      const subTasks = pollSubTasks.reduce<SubTask[]>((acc, subTask) => {
        const filteredSubTasks = subTask.subTasks.filter((st) => st.title.trim() !== '')
        return acc.concat(filteredSubTasks)
      }, [])

      const pollBody: TaskCreationBody = {
        groups: selectedGroupsIds,
        users: taskUsers,
        tags: selectedTagsIds ?? [],
        from_ts: fromTs,
        to_ts: removalDate,
        deadline: deadLine,
        utc_offset: 3,
        send_schedule_push: true,
        send_campaign_start_push: true,
        data: {
          title: questionText,
          is_recurring: isRecurring,
          recurrence_frequency: isRecurring ? recurrenceFrequency : null,
          recurrence_days_of_week: null,
          recurrence_interval: isRecurring ? recurrenceInterval : null,
          recurrence_start_time: isRecurring ? recurrenceStartTime : null,
          desc: pollDescription,
          categories: ['Poll'],
          view: taskView!,
          type: keys.TASK_V2_TYPES.POLL,
          is_individual_results: isSwitchIndividualResultsActive,
        },
        sub_tasks: [
          {
            title: questionText,
            type: keys.SUBTASK_V2_TYPES.POLL_SUBTASK,
            is_required: true,
            is_multi_choice: isSwitchMultipleAnswersActive,
            category: 'Poll',
            options,
          },
          ...subTasks,
        ],
      }

      const isTaskCreated = await dispatch(createTask(pollBody))
      if (isTaskCreated) {
        closeModal()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const submitActionHandler = () => {
    if (isEditing) {
      onEditPost()
      return
    }

    const actionMap = {
      [OptionType.POST]: onCreatePost,
      [OptionType.POP]: onCreatePop,
      [OptionType.CHAT]: onCreateGroup,
      [OptionType.POLL]: createPoll,
    }

    const action = actionMap[selectedOptionType]
    if (action) {
      action()
    }
  }
  const onOptionChange = (optionType: string) => {
    setSelectedOptionType(optionType)
    if (optionType === OptionType.POP) {
      setSelectedTagsIds([])
    }
  }

  useEffect(() => {
    if (
      !isAudienceOptionsModalOpen &&
      !selectedGroupsIds.length &&
      !selectedUsersUids.length &&
      !selectedTagsIds.length &&
      !groupsToPayload.length &&
      !isEditing
    ) {
      const defaultAudience: object | null = getLocalStorageItem('defaultAudience')
      let hasProcessed = false

      if (defaultAudience && defaultAudience[uid] && treeGroups && !isEmpty(treeGroups)) {
        const { users, groups }: { users: string[]; groups: string[] } = defaultAudience[uid]
        const matchingGroups = groups.filter((group) => Object.keys(treeGroups).includes(group))
        const matchingUsers = users.filter((user) => Object.keys(retailUsersObject!).includes(user))

        if (matchingUsers.length) {
          setSelectedUsersUids(users.filter((uid) => Object.keys(retailUsersObject!).includes(uid)))
          hasProcessed = true
        }
        if (matchingGroups.length) {
          const treeNodesIds = groups.flatMap((group) => getTreeNodesIds(treeGroups, group))
          setGroupsToPayload(groups)
          setSelectedGroupsIds(treeNodesIds)
          hasProcessed = true
        }
      }

      if (!hasProcessed && default_group !== null && treeGroups && !isEmpty(treeGroups)) {
        setGroupsToPayload([default_group])
        setSelectedGroupsIds(getTreeNodesIds(treeGroups, default_group))
      }
    }
  }, [
    default_group,
    treeGroups,
    isOpen,
    retailUsersObject,
    selectedUsersUids,
    groupsToPayload,
    isAudienceOptionsModalOpen,
    selectedGroupsIds.length,
    uid,
    isEditing,
    setSelectedUsersUids,
    setGroupsToPayload,
    setSelectedGroupsIds,
    selectedTagsIds,
  ])

  return (
    <BaseModal
      display={isLinkAttachmentModalOpen || isAudienceOptionsModalOpen ? 'none' : 'block'}
      isShowAnimation={true}
      isOpen={isOpen}
      maxWidth="700px"
      minWidth="650px"
      onClose={closeModal}
      hideCloseButton={isAudienceOptionsModalOpen}
      headerContent={
        <>
          <Text mb="10px">
            <LocaleText text={!isEditing ? 'create_new_pop_post' : 'edit'} />
          </Text>
          <Divider />
          <AudienceSelectionBlock
            isEditing={isEditing}
            avatar={avatar}
            first_name={first_name}
            last_name={last_name}
            user_role={user_role}
            clearState={clearAudienceState}
            splittedGroupNames={splittedGroupNames}
            namesForTooltip={namesForTooltip}
            selectedOptionType={selectedOptionType}
            isSwitchPopActive={isSwitchPopActive}
            onTogglePopSwitch={onTogglePopSwitch}
            isSwitchAnnouncementActive={isSwitchAnnouncementActive}
            onToggleAnnouncementSwitch={onToggleAnnouncementSwitch}
            setIsAudienceOptionsModalOpen={setIsAudienceOptionsModalOpen}
            uploadedImagesURL={uploadedImagesURL}
            imageInputRef={imageInputRef}
            handleSingleMediaUploading={handleSingleMediaUploading}
            isSwitchIndividualResultsActive={isSwitchIndividualResultsActive}
            onToggleIndividualResultsSwitch={onToggleIndividualResultsSwitch}
          />
          <Divider mt="10px" mb="20px" />
          {!isEditing && (
            <Box mx="40px">
              <Switcher options={options} onOptionChange={onOptionChange} selectedOption={selectedOptionType} />
            </Box>
          )}
          <Divider my="20px" />
        </>
      }
      bodyContent={
        <Box px="6" minH="350px">
          <InputBlock
            selectedOptionType={selectedOptionType}
            editorValue={editorValue}
            setEditorValue={setEditorValue}
            setTitle={setTitle}
            title={title}
            placeholder={t_create_post_placeholder}
            message={message}
            setMessage={setMessage}
            postType={postType}
            isEditing={isEditing}
            isSwitchMultipleAnswersActive={isSwitchMultipleAnswersActive}
            setIsSwitchMultipleAnswersActive={setIsSwitchMultipleAnswersActive}
            setFromTs={setFromTs}
            setDeadline={setDeadLine}
            questionText={questionText}
            setQuestionText={setQuestionText}
            pollOptions={pollOptions}
            setPollOptions={setPollOptions}
            setIsRecurring={setIsRecurring}
            setRecurrenceInterval={setRecurrenceInterval}
            setRecurrenceFrequency={setRecurrenceFrequency}
            recurrenceInterval={recurrenceInterval}
            recurrenceFrequency={recurrenceFrequency}
            setPollSubTasks={setPollSubTasks}
            setIsValidPollSubtasks={setIsValidPollSubtasks}
            setPollDescription={setPollDescription}
            setTaskView={setTaskView}
            taskView={taskView}
          />

          <MediaContentBlock
            validLinks={validLinks}
            audioRecords={audioRecords}
            uploadedFilesURL={uploadedFilesURL}
            uploadedImagesURL={uploadedImagesURL}
            uploadedVideosURL={uploadedVideosURL}
            media={media}
            addMediaHandler={addMediaHandler}
            handlerDeleteAllMedia={handlerDeleteAllMedia}
            handleDeleteMedia={handleDeleteMedia}
            removeAudioRecord={removeAudioRecord}
            deleteFileHandler={deleteFileHandler}
            selectedOptionType={selectedOptionType}
          />

          {isAudienceOptionsModalOpen && (
            <AudiencePublicationsModal
              isOpen={isAudienceOptionsModalOpen}
              groups={treeGroups}
              users={treeUsers}
              onClose={onClose}
              setShowOptionsList={setIsAudienceOptionsModalOpen}
              renderViewOptionModal={renderViewOptionModal}
              selectedUsersUids={selectedUsersUids}
              selectedOptionType={selectedOptionType}
              selectedTagsIds={selectedTagsIds}
              groupsToPayload={
                selectedOptionType === OptionType.CHAT || selectedOptionType === OptionType.POLL
                  ? selectedGroupsIds
                  : groupsToPayload ?? []
              }
            />
          )}
          {isLinkAttachmentModalOpen && (
            <LinkAttachmentModal
              isOpen={isLinkAttachmentModalOpen}
              closeModal={onCLoseLinkAttachmentModalOpen}
              setAttachedLink={setAttachedLink}
              attachedLink={attachedLink}
            />
          )}
        </Box>
      }
      footerContent={
        <>
          {!isAudienceOptionsModalOpen && (
            <PostModalFooter
              selectedOptionType={selectedOptionType}
              fileInputRef={fileInputRef}
              imageInputRef={imageInputRef}
              handleMediaUpload={handleMediaUploading}
              attachedLink={attachedLink}
              isDisabledPublishButton={isDisabledPublishButton()}
              handleFileUpload={handleFileUpload}
              formFileRef={formFileRef}
              onSuccessAudioRecording={sendAudioRecord}
              submitActionHandler={submitActionHandler}
              onOpenLinkAttachmentModal={onOpenLinkAttachmentModal}
              handleSingleMediaUploading={handleSingleMediaUploading}
            />
          )}
        </>
      }
    />
  )
}
